import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import TitleText from 'assets/shared/TitleText.svg'
import queries from 'styles/breakpoints'

const StyledLink = styled(Link)`
  line-height: 1;
`

const Wrapper = styled.div`
  width: 7.0625rem;
  svg {
    width: auto;
    height: auto;
  }

  @media ${queries.laptopUp} {
    width: 20rem;
    padding-left=10;
  }
`
const Title = (): JSX.Element => {
  return (
    <StyledLink to="/">
      <Wrapper>
        <img src={TitleText} alt="Galleria soryans - home" />
      </Wrapper>
    </StyledLink>
  )
}

export default Title
