/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from 'styled-components/macro'
import Macy from 'macy'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import queries from 'styles/breakpoints'

import data from 'data/data.json'
import GalleryCard from 'components/molecules/GalleryCard'
import { pageAnimation, galleryAnimation } from 'utils/animations'
import { resetSlider } from 'store/slidesSlice'

const Wrapper = styled(motion.main)`
  padding: 1.5rem;
  margin: 0 auto;
  max-width: var(--max-width);
  @media ${queries.tabletUp} {
    padding-top: 2.5rem;
  }
`
const List = styled(motion.ul)`
  margin: 0;
  img {
    width: 100%;
  }
`

const macyOptions = {
  container: '#macy-grid',
  trueOrder: true,
  mobileFirst: true,
  margin: 24,
  columns: 1,
  breakAt: {
    1000: 4,
    650: {
      margin: 40,
      columns: 2,
    },
  },
}

const Gallery = (): JSX.Element => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetSlider())
  }, [dispatch])

  useEffect(() => {
    new Macy(macyOptions)
  }, [])

  return (
    <Wrapper exit="exit" variants={pageAnimation} initial="hide" animate="show">
      <h1 className="sr-only">Main Gallery</h1>
      <List
        id="macy-grid"
        initial="hide"
        animate="show"
        variants={galleryAnimation}
      >
        {data.map((painting, index) => {
          return <GalleryCard {...painting} id={index} key={index} />
        })}
      </List>

      <footer className="footer-distributed">
        <div className="footer-center">
          <h3>
            Game<span>Mithra</span>
          </h3>
          <div>
            <i className="fa fa-envelope"></i>
            <p>
              <a href="mailto:support@company.com">sooryans@mail.com</a>
            </p>
          </div>
          <div>
            <i className="fa fa-phone"></i>
            <p>+91 9449483594</p>
          </div>
        </div>

        <div className="footer-right">
          <p className="footer-company-about">
            <span>About the paintings</span>
            updating our heritage through my art
          </p>

          <div className="footer-icons">
            <a
              href="https://www.facebook.com/GameMithra/ "
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/gamemithra/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-twitter"></i>
            </a>
          </div>
        </div>
        <div>
          <div className="footer-link-position">
            <div className="footer-center">
              <p className="footer-links">
                <a href="#" className="link-1">
                  Home
                </a>
                <a href="#">Blog</a>
                <a href="#">Pricing</a>
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className="footer-CopyRight">GameMithra © 2022</p>
        </div>
      </footer>
    </Wrapper>
  )
}

export default Gallery
